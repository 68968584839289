


// Bind to the click of all links with a #hash in the href
$('[href*="#"]').click(function(e) {
	var is_home = $("body").hasClass("slug_home");
	var is_top = $(this).attr("href") == "#top";
	if (is_home || is_top) {
		e.preventDefault();
		$(window)
			.stop(true)
			.scrollTo(
				this.hash,
				{
					duration:1000,
					interrupt:true
				}
			);
	}
});


var mySwiper = new Swiper ('.swiper-container', {
	pagination          : '.swiper-pagination',
	nextButton          : '.swiper-button-next',
	prevButton          : '.swiper-button-prev',
	slidesPerView       : 1,
	paginationClickable : true,
	loop                : true,
});
